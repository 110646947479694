.text-justify {
  text-align: justify;
}

.main-title {
  padding: 180px 15% 0 15%;
}

@media (min-width: 1768px) {
  .main-title {
    font-size: 7rem;
  }
  .main-image {
    width: 100%;
    height: 1150px;
  }
}

.main-menu {
  background-color: #56101B;
}

#main-menu {
  background-color: #56101B;
}

.team-row {
  display: flex;
  justify-content: center;
}



img, img::before, img::after {
  transition: none !important;
  transform: none !important;
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
}


.tp-testi-reivew {
  font-size: 1.2rem;
}

.footer-column {
  width: 33.33%;
  text-align: center;
}

.tp-ab-bg {
  background-size: cover;
  background-position: center;
  aspect-ratio: 16 / 9; /* Adjust the ratio as needed */
}
/* Service Card */
.tp-service {
  cursor: pointer;
  position: relative; /* Ensure child elements can be animated relative to this card */
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

/* Ensure the space is already reserved */
.tp-service__text {
  visibility: hidden; /* Hide text but keep space reserved */
  overflow: hidden; /* Hide the overflow to create a typing effect */
  white-space: nowrap; /* Prevent text from wrapping */
  border-right: 2px solid rgba(0, 0, 0, 0.75); /* Optional: blinking cursor effect */
  width: 0; /* Start with no width */
  opacity: 0; /* Start with no opacity */
  transition: all 0.5s ease-out;
}

/* Card hover effects */
.tp-service:hover .tp-service__text {
  visibility: visible; /* Make text visible */
  width: 100%; /* Animate to full width */
  opacity: 1; /* Fade in the text */
  animation: typing 1s steps(30, end) forwards, blink-caret 0.75s step-end infinite;
}

/* Card Shadow and move up on hover */
.tp-service:hover {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  transform: translateY(-20px);
  cursor: pointer;
}

/* Typing effect animation */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* Optional: Blinking cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: rgba(0, 0, 0, 0.75);
  }
}


.animation-container {
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 20px 20px 0 20px;
  margin: 20px;
  height: 500px;
  position: relative;
  overflow: visible;
}

.ilayda-img-color,
.ilayda-img-black {
  width: 85%;
}

/* Hide color image by default */
.ilayda-img-color {
  opacity: 1;
}

/* Show color image on hover */

.info-text {
  font-size: 1.2rem;
  color: #fff;
  margin-bottom: 20px;
}

.info-button {
  background-color: #56101B;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1.2rem;
  cursor: pointer;
}

.title-text {
  text-align: center;
  margin-top: 20px;
}

.image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ilayda-img-color-background {
  width: 45%;
  object-fit: cover;
  align-self: center;
  justify-self: center;
}

@media (max-width: 768px) {
  .ilayda-img-color {
    width: 65%;
  }
}

@media (max-width: 480px) {
  .ilayda-img-color {
    width: 75%;
  }
  .animation-container {
    height: 250px;
  }
}
