.cookieBanner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    z-index: 1000;
    text-align: center;
}

.cookieBanner p {
    margin: 0;
    font-weight: 550;
    color: white;
    margin-bottom: 15px;
}

.cookieBanner div {
    display: flex;
    gap: 10px;
}

.cookieBanner button {
    padding: 10px 20px;
    background-color: #0070f3;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.cookieBanner .rejectBtn {
    background-color: red;
}

.cookieBanner .acceptBtn {
    background-color: green;
}

.cookieBanner button:hover {
    opacity: 0.7;
}

.cookieBanner .link {
    color: #0080FF;
}
