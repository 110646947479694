@use '../utils' as *;


/*----------------------------------------*/
/*  13. PROJECT CSS START
/*----------------------------------------*/

.pj-detials-page {
    @media #{$xs} {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

.pj-list__img {
    margin-bottom: 20px;
    overflow: hidden;

    & :hover {
        transform: scale(1.1);
    }
}

.pj-list-item {
    & span {
        font-size: 12px;
        line-height: 22px;
        font-family: var(--tp-ff-montserrat);
        letter-spacing: .18em;
        text-transform: uppercase;
        margin-bottom: 10px;
        display: inline-block;
    }
}

.pj-list__title {
    font-size: 24px;
    font-weight: 400;

    & a {
        background-image: linear-gradient(#000, #000), linear-gradient(#000, #000);
        display: inline;
        background-size: 0% 1px, 0 1px;
        background-position: 100% 100%, 0 100%;
        background-repeat: no-repeat;
        transition: background-size 0.4s linear;

        &:hover {
            color: #000;
            background-size: 0 1px, 100% 1px;
        }

    }
}

.pj-detials-meta-box {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 30px 60px 5px 55px;
    width: 50%;

    @media #{$lg} {
        width: 65%;
    }

    @media #{$md,$xs} {
        position: static;
        padding: 30px 0;
        width: 100%;
    }
}

.pj-details-meta-title {
    font-size: 43px;
    margin-bottom: 35px;
}

.pj-detials-meta-info {
    & span {
        margin: 15px 0;
        display: block;
        font-size: 18px;
        width: 50%;
        float: left;

        @media #{$md,$xs} {
            width: 100%;
        }

        & a {
            &:hover {

                color: var(--tp-common-black);
            }
        }

        & b {
            color: var(--tp-common-black);
            font-weight: 500;
            margin-right: 5px;
        }
    }
}

.pj-share-icons {
    & a {
        text-align: center;
        font-size: 14px;
        width: 30px;
        height: 30px;
        border: 1px solid #F1F1F1;
        border-radius: 3px;
        display: inline-block;
        margin-right: 5px;

        &:hover {
            background-color: var(--tp-common-black);
            color: var(--tp-common-white) !important;
        }
    }
}

.pj-detials-title {
    font-size: 36px;
    margin-bottom: 35px;
    color: var(--tp-heading-2);
}

.pj-detials-contact {
    & p {
        margin-bottom: 40px;
    }
}

.pj-detials-pagenation {
    & span {
        font-size: 16px;
        text-transform: uppercase;
        color: var(--tp-common-black);
        letter-spacing: .18em;
        line-height: 21px;
        font-weight: 500;
    }

    & i {
        font-size: 20px;
    }

}